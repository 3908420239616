<template>
  <div style="display: flex;justify-content: center;align-items: center">
    <el-transfer
      filterable
      :filter-method="filterMethod"
      v-model="value"
      :data="data"
      :titles="titles"
      @change="changeAssign"
    ></el-transfer>
  </div>
</template>

<script>
import { getAccountAll, updateAccount } from '@/api/youTube.js'

export default {
  name: 'accountAssign',
  // import引入的组件需要注入到对象中才能使用",
  components: {},
  props: {
    userId: Number
  },
  data() {
    // 这里存放数据",
    return {
      titles: ['可分配的帐号', '已分配的帐号'],
      data: [],
      value: [],
      filterMethod(query, item) {
        return item.label.indexOf(query) > -1
      }
    }
  },
  // 监听属性 类似于data概念",
  computed: {},
  // 监控data中的数据变化",
  watch: {
    userId(userId) {
      if (userId !== null) {
        this.data = []
        this.value = []
        this.fetchData(userId)
      } else {
        this.data = []
        this.value = []
      }
    }
  },
  // 方法集合",
  methods: {
    async fetchData(userId) {
      // TODO 暂时使用一个较大的pageSize代替
      // const freeAccounts = []
      const freeAccounts = await getAccountAll({
        is_test: false,
        user__isnull: true,
        status: 1
      })

      const ownAccounts = await getAccountAll({
        user: userId
      })

      const allAccounts = freeAccounts.concat(ownAccounts)

      ownAccounts.forEach(currentValue => {
        this.value.push(currentValue.id)
      })

      allAccounts.forEach(currentValue => {
        // 判断帐号状态
        let disabled = false
        if (currentValue.status !== 1) {
          disabled = true
        }

        this.data.push({
          key: currentValue.id,
          label: currentValue.id + '-' + currentValue.name,
          disabled
        })
      })
    },
    async changeAssign(value, direction, movedKeys) {
      // console.log(value, direction, movedKeys)
      if (direction === 'right') {
        movedKeys.forEach(async id => {
          await updateAccount(id, {
            user: this.userId
          })
        })
      } else {
        movedKeys.forEach(async id => {
          await updateAccount(id, {
            user: ''
          })
        })
      }
    }
  },
  // 生命周期 - 创建之前",数据模型未加载,方法未加载,html模板未加载
  beforeCreate() {},

  // 生命周期 - 创建完成（可以访问当前this实例）",数据模型已加载，方法已加载,html模板已加载,html模板未渲染
  created() {},
  // 生命周期 - 挂载之前",html模板未渲染
  beforeMount() {},

  // 生命周期 - 挂载完成（可以访问DOM元素）",html模板已渲染
  mounted() {
    this.fetchData(this.userId)
  },

  // 生命周期 - 更新之前",数据模型已更新,html模板未更新
  beforeUpdate() {},
  // 生命周期 - 更新之后",数据模型已更新,html模板已更新
  updated() {},
  // 生命周期 - 销毁之前",
  beforeDestroy() {},
  destroyed() {},
  // 生命周期 - 销毁完成",
  // 如果页面有keep-alive缓存功能，这个函数会触发",
  activated() {}
}

// "http-get请求": {
//   "prefix": "httpget",
//     "body": [
//     "this.\({",
//     "url: this.\\$http.adornUrl(''),",
//     "method: 'get',",
//     "params: this.\\$http.adornParams({})",
//     "}).then(({ data }) => {",
//     "})"
//   ],
//     "description": "httpGET请求"
// },
// "http-post请求": {
//   "prefix": "httppost",
//     "body": [
//     "this.\({",
//     "url: this.\\$http.adornUrl(''),",
//     "method: 'post',",
//     "data: this.\\$http.adornData(data, false)",
//     "}).then(({ data }) => { });"
//   ],
//     "description": "httpPOST请求"
// }
// }
</script>

<style></style>
