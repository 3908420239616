<template>
  <div style="display: flex;justify-content: center;align-items: center">
    <el-form
      ref="pointsForm"
      :model="pointsForm"
      :rules="pointsFormRules"
      label-width="100px"
    >
      <el-form-item
        label="修改积分"
        prop="pointsValue"
        :rules="[
          { required: true, message: '积分值不能为空' },
          { type: 'number', min: 0, message: '积分必须为正整数' }
        ]"
      >
        <el-input
          v-model.number="pointsForm.pointsValue"
          style="width:100px"
          placeholder="请输入积分"
        ></el-input>
      </el-form-item>

      <el-form-item
        label="备注"
        prop="remark"
        :rules="[{ required: true, message: '备注不能为空' }]"
      >
        <el-input
          v-model="pointsForm.remark"
          style="width:400px"
          type="textarea"
          placeholder="请输入备注"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="emitPoints(pointsForm.pointsValue)">
          增添
        </el-button>
        <el-button type="warning" @click="emitPoints(-pointsForm.pointsValue)">
          扣减
        </el-button>
        <el-button @click="closePoints">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { changePoints } from '@/api/points'
export default {
  props: {
    userId: Number
  },
  data() {
    return {
      pointsForm: { pointsValue: '' },
      pointsFormRules: {
        pointsValue: [
          { required: true, message: '积分值不能为空' },
          { type: 'number', message: '积分必须为数字值' },
          { min: 0, message: '最小值不能小于0' }
        ]
      }
    }
  },
  methods: {
    // 点击增减积分
    async emitPoints(value) {
      await this.$refs.pointsForm.validate(async valid => {
        if (valid) {
          await changePoints(
            this.userId,
            parseInt(value),
            this.pointsForm.remark
          )
          this.$message.success('积分修改成功!')
          this.closePoints()
        } else {
          return false
        }
      })
    },
    // 点击取消
    closePoints() {
      this.$refs.pointsForm.resetFields()
      this.pointsForm.pointsValue = ''
      this.$emit('pointsDialog', false)
    }
  }
}
</script>

<style scoped></style>
