<template>
  <div>
    <el-form  :model="userForm" class="demo-form-inline" :rules="rules" ref="user" label-width="80px">
      <el-form-item label="用户名" prop="username">
        <el-input v-model="userForm.username" placeholder="用户名"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input v-model="userForm.password" placeholder="密码"></el-input>
      </el-form-item>
      <el-form-item label="权限" prop="checklist">
        <el-checkbox-group v-model="userForm.checklist">
          <el-checkbox label="ADMIN" border >管理员</el-checkbox>
          <el-checkbox label="USER" border >用户</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="结束日期">
        <el-date-picker
          v-model="userForm.endTime"
          align="right"
          type="date"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择日期"
          :picker-options="pickerOptions">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="备注">
        <el-input type="textarea" :rows="2" v-model="userForm.note"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('user')">添加</el-button>
        <el-button @click="resetForm('user')">重置</el-button>
      </el-form-item>
    </el-form>

  </div>
</template>

<script>
import { addUser } from '@/api/user'

export default {
  name: 'userForm',
  // props: {
  //   username: {
  //     type: String,
  //     default: ''
  //   }
  // },
  data() {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date())
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', date)
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', date)
          }
        }]
      },
      userForm: {
        checklist: []
      },
      user: {},
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 5, message: '长度至少5个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 5, message: '长度至少5个字符', trigger: 'blur' }
        ],
        checklist: [
          { type: 'array', required: true, message: '至少选择一种权限', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let roles = ''
          if (this.userForm.checklist.length === 1) {
            roles = this.userForm.checklist[0]
          } else if (this.userForm.checklist.length === 2) {
            roles = this.userForm.checklist[0] + '|' + this.userForm.checklist[1]
          }
          this.user.roles = roles
          this.user.username = this.userForm.username
          this.user.password = this.userForm.password
          this.user.endTime = this.userForm.endTime
          this.user.note = this.userForm.note
          addUser(this.user).then(resp => {
            this.$refs[formName].resetFields()
            this.userForm.endTime = ''
            this.userForm.note = ''
            this.$emit('initPage')
          })
        } else {
          return false
        }
      })
    },
    resetForm(formName) {
      this[formName] = {}
      this.$nextTick(() => {
        this.$refs[formName].resetFields()
        this.userForm.endTime = ''
        this.userForm.note = ''
      })
    }
  }
}
</script>

<style scoped>

</style>
