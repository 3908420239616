<template>
  <div class="app-container">
    <el-form :inline="true" :model="searchUser" class="demo-form-inline">
      <el-form-item label="用户ID">
        <el-input v-model="searchUser.id" placeholder="用户ID"></el-input>
      </el-form-item>
      <el-form-item label="用户名">
        <el-input v-model="searchUser.username" placeholder="用户名"></el-input>
      </el-form-item>
      <el-form-item label="权限">
        <el-select
          v-model="searchUser.is_staff"
          placeholder="用户OR管理员"
          clearable
        >
          <el-option label="管理员" value="true"></el-option>
          <el-option label="用户" value="false"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="类型">
        <el-select
          v-model="searchUser.is_test"
          placeholder="试用OR正式"
          clearable
        >
          <el-option label="试用用户" value="true"></el-option>
          <el-option label="正式用户" value="false"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="管控">
        <el-select
          v-model="searchUser.check_risk"
          placeholder="是否对推文进行风险管控"
          clearable
        >
          <el-option label="风险管控" value="true"></el-option>
          <el-option label="取消管控" value="false"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="搜索">
        <el-input
          v-model="searchUser.search"
          placeholder="用户名、备注等..."
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSearch">查询</el-button>
      </el-form-item>
      <!-- <el-form-item>
        <el-button type="primary" @click="addUser">添加用户</el-button>
      </el-form-item> -->
      <!-- <el-form-item>
        <el-button
          type="primary"
          @click="updateUserInfo"
          v-loading.fullscreen.lock="fullscreenLoading"
        >
          全局更新用户信息
        </el-button>
      </el-form-item> -->
    </el-form>
    <el-table
      :data="tableData"
      style="width: 100%"
      fit
      border
      highlight-current-row
      class="tableList3"
    >
      <el-table-column prop="id" label="ID" width="100"></el-table-column>
      <el-table-column prop="username" label="用户名"></el-table-column>
      <!-- <el-table-column prop="status" label="状态" width="100">
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.status === 1 ? 'success' : 'danger'"
            disable-transitions
          >
            {{ scope.row.status === 1 ? '正常' : '禁用' }}
          </el-tag>
        </template>
      </el-table-column> -->
      <!-- <el-table-column prop="create_time" label="创建时间"></el-table-column>
      <el-table-column prop="endTime" label="到期时间"></el-table-column> -->
      <el-table-column prop="points" label="积分"></el-table-column>
      <el-table-column prop="roles" label="权限"></el-table-column>
      <el-table-column prop="is_test" label="试用用户">
        <template slot-scope="scope">
          <!-- {{ scope.row.is_test ? '是' : '否' }} -->
          <el-popconfirm
            title="确定更改帐号的状态吗?"
            @confirm="
              saveEdit(scope.row.id, { is_test: scope.row.is_test }, $event)
            "
            @cancel="scope.row.is_test = !scope.row.is_test"
          >
            <el-switch
              v-model="scope.row.is_test"
              active-color="#13ce66"
              inactive-color="#ff4949"
              slot="reference"
            ></el-switch>
          </el-popconfirm>
        </template>
      </el-table-column>
      <el-table-column prop="check_risk" label="风险管控">
        <template slot-scope="scope">
          <!-- {{ scope.row.is_test ? '是' : '否' }} -->
          <el-popconfirm
            title="确定更改帐号的状态吗?"
            @confirm="
              saveEdit(
                scope.row.id,
                { check_risk: scope.row.check_risk },
                $event
              )
            "
            @cancel="scope.row.check_risk = !scope.row.check_risk"
          >
            <el-switch
              v-model="scope.row.check_risk"
              active-color="#13ce66"
              inactive-color="#ff4949"
              slot="reference"
            ></el-switch>
          </el-popconfirm>
        </template>
      </el-table-column>
      <el-table-column
        prop="buy_twitter_account"
        label="推特账号购买"
      ></el-table-column>
      <el-table-column label="推特账号累计">
        <template slot-scope="scope">
          <span>
            {{
              scope.row.twitter_accounts ? scope.row.twitter_accounts.length : 0
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="buy_facebook_account"
        label="脸书账号购买"
      ></el-table-column>
      <el-table-column label="脸书账号累计">
        <template slot-scope="scope">
          <span>
            {{
              scope.row.facebook_accounts
                ? scope.row.facebook_accounts.length
                : 0
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="buy_youtube_account"
        label="油管账号购买"
      ></el-table-column>
      <el-table-column label="油管账号累计">
        <template slot-scope="scope">
          <span>
            {{
              scope.row.youtube_accounts ? scope.row.youtube_accounts.length : 0
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="note" label="备注"></el-table-column>
      <el-table-column label="操作" width="200px">
        <template slot-scope="scope">
          <el-button
            @click.native.prevent="assignAccount(scope.row.id, 'twitter')"
            type="text"
            size="small"
          >
            推特帐号
          </el-button>
          <el-button
            @click.native.prevent="assignAccount(scope.row.id, 'facebook')"
            type="text"
            size="small"
          >
            脸书帐号
          </el-button>
          <el-button
            @click.native.prevent="assignAccount(scope.row.id, 'youTube')"
            type="text"
            size="small"
          >
            油管帐号
          </el-button>
          <!-- <el-button
            class="leftrow"
            style="color: #E6A23C"
            @click.native.prevent="resetPwd(scope.row.id)"
            type="text"
            size="small"
          >
            重置密码
          </el-button> -->
          <!-- <el-button
            :style="{ color: scope.row.status === 1 ? '#F56C6C' : '#67C23A' }"
            @click.native.prevent="updateStatus(scope.row)"
            type="text"
            size="small"
          >
            {{ scope.row.status === 1 ? '禁用用户' : '启用用户' }}
          </el-button> -->

          <!-- 修改积分 -->
          <el-button
            @click.native.prevent="assignAccount(scope.row.id, '修改积分')"
            type="text"
            size="small"
          >
            修改积分
          </el-button>
          <el-button
            @click.native.prevent="handleBuyInfo(scope.row)"
            type="text"
            size="small"
          >
            购买信息
          </el-button>
          <!-- 查看积分记录 -->
          <el-button
            type="text"
            size="small"
            class="leftrow"
            @click="pointsMenu(scope.row)"
          >
            查看积分详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        :page-size="limit"
        :page-sizes="[limit, 20, 50, 100]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <!--添加用户的from 开始-->
    <el-dialog title="添加用户" :visible.sync="showUserForm" width="30%">
      <userForm @initPage="pageInit"></userForm>
      <span slot="footer" class="dialog-footer"></span>
    </el-dialog>
    <!--添加用户的form 结束-->

    <!--账号分配 开始-->
    <el-dialog
      :title="showAccountAssignTitle"
      :visible.sync="showAccountAssign"
    >
      <twitter-account-assign
        v-if="showAccountAssignTitle === 'twitter'"
        :userId.sync="editUserId"
      ></twitter-account-assign>
      <facebook-account-assign
        v-if="showAccountAssignTitle === 'facebook'"
        :userId.sync="editUserId"
      ></facebook-account-assign>
      <youTubeAccountAssign
        v-if="showAccountAssignTitle === 'youTube'"
        :userId.sync="editUserId"
      ></youTubeAccountAssign>
      <Points
        v-if="showAccountAssignTitle === '修改积分'"
        :userId.sync="editUserId"
        @pointsDialog="pointsDialogValue"
      ></Points>
      <span slot="footer" class="dialog-footer"></span>
    </el-dialog>
    <!--账号分配 结束-->
    <el-dialog :visible.sync="buyInfoDialogShow">
      <el-form ref="form" :model="buyInfoForm">
        <el-form-item label="twitter帐号购买数量">
          <el-input
            v-model="buyInfoForm.buy_twitter_account"
            placeholder="twitter帐号"
          ></el-input>
        </el-form-item>
        <el-form-item label="facebook帐号购买数量">
          <el-input
            v-model="buyInfoForm.buy_facebook_account"
            placeholder="facebook帐号"
          ></el-input>
        </el-form-item>
        <el-form-item label="youtube帐号购买数量">
          <el-input
            v-model="buyInfoForm.buy_youtube_account"
            placeholder="youtube帐号"
          ></el-input>
        </el-form-item>
        <el-form>
          <el-button type="primary" @click="updateBuyInfo">
            立即修改
          </el-button>
        </el-form>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  resetPwd,
  updateColumn,
  updateUserInfo,
  getUsers,
  updateUser
} from '@/api/user'
import UserForm from '@/components/manage/addUser'
import TwitterAccountAssign from '@/components/twitter/AccountAssign'
import FacebookAccountAssign from '@/components/facebook/AccountAssign'
import YouTubeAccountAssign from '@/components/youTube/AccountAssign'
import Points from '@/components/points'

export default {
  name: 'userSettings',
  components: {
    TwitterAccountAssign,
    FacebookAccountAssign,
    YouTubeAccountAssign,
    UserForm,
    Points
  },
  data() {
    return {
      fullscreenLoading: false,
      showUserForm: false,
      showAccountAssign: false,
      showAccountAssignTitle: null,
      tableData: [],
      searchUser: {},
      page: 1,
      limit: 10,
      total: 0,
      editUserId: null,
      buyInfoForm: {
        buy_twitter_account: 0,
        buy_facebook_account: 0,
        buy_youtube_account: 0
      },
      buyInfoDialogShow: false
    }
  },
  created() {
    this.pageInit()
  },
  methods: {
    pageInit() {
      this.queryData()
    },
    queryData() {
      getUsers(
        Object.assign(
          { pageIndex: this.page, pageSize: this.limit },
          this.searchUser
        )
      ).then(resp => {
        this.tableData = resp.items
        this.total = resp.all_count
        console.log(this.tableData)
      })
    },
    assignAccount(id, type) {
      // 分配账号
      this.editUserId = id
      this.showAccountAssign = true
      this.showAccountAssignTitle = type
    },
    handleSizeChange(val) {
      // 当每页显示条数发生变化时
      this.limit = val
      this.queryData()
    },
    handleCurrentChange(val) {
      // 当页码发生变化时
      this.page = val
      this.queryData()
    },
    handleBuyInfo(row) {
      // 当页码发生变化时
      this.editUserId = row.id
      this.buyInfoForm.buy_twitter_account = row.buy_twitter_account
      this.buyInfoForm.buy_facebook_account = row.buy_facebook_account
      this.buyInfoForm.buy_youtube_account = row.buy_youtube_account
      this.buyInfoDialogShow = true
    },
    resetPwd(id) {
      // 重置密码
      this.$confirm(
        '点击确定后此账号密码将会被重置为123456789, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => {
          resetPwd(id)
        })
        .catch(() => {})
    },
    async updateStatus(row) {
      // 修改用户状态
      await updateColumn(row.id, 'status', row.status === 1 ? 2 : 1).then(
        resp => {
          this.pageInit()
        }
      )
    },
    async updateBuyInfo() {
      await updateUser(this.editUserId, this.buyInfoForm)
      this.queryData()
      this.buyInfoDialogShow = false
    },
    onSearch() {
      this.page = 1
      this.queryData()
    },
    addUser() {
      this.showUserForm = true
    },
    async updateUserInfo() {
      this.fullscreenLoading = true
      await updateUserInfo().then(resp => {
        this.pageInit()
      })
      this.fullscreenLoading = false
    },
    // 积分弹窗关闭
    pointsDialogValue(data) {
      this.showAccountAssign = data
      this.queryData()
    },
    // 积分页跳转
    pointsMenu(row) {
      this.$router.push({ path: 'pointsMenu', query: { id: row.id } })
    },
    async saveEdit(accountId, data) {
      // row.edit = false
      await updateUser(accountId, data)
      this.queryData()
    }
  }
}
</script>

<style scoped>
.leftrow {
  margin-left: 0;
}
</style>
